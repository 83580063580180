<template>
    <div v-if="me" class="container">
        <StackRouterHeaderBar :title="'직장 인증하기'" :show-title="true" />
        <main class="wrapper">
            <div class="title m-b-8">인증방법을 선택해주세요</div>
            <div class="required m-b-20">
                <div class="menu" v-for="menu in menus" :key="menu.id" @click="onClickMenu(menu)">
                    <span class="f-medium c-black f-16" v-html="menu.name" />
                </div>
            </div>
            <span @click="onClickCertificate()" class="help-Certificate">마땅한 인증 방법이 없어요 </span>
        </main>
    </div>
</template>

<script>
import verificationBadgeService from '@/services/verification-badge'

export default {
    name: 'CertificateCompaniesPage',
    mounted() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        menus() {
            return [
                {
                    id: 1,
                    name: '명함/사원증',
                    route: '',
                },
                {
                    id: 2,
                    name: '재직증명서',
                    route: '',
                },
                {
                    id: 3,
                    name: '사업자등록증',
                    route: '',
                },
                {
                    id: 4,
                    name: '자격증/면허증',
                    route: '',
                },
                {
                    id: 5,
                    name: '건강보험 자격득실확인서',
                    route: '',
                },
            ]
        },
        verifyStatus() {
            const badges = this.$store.getters.verificationBadges
            if (!badges || !badges.length) return false
            return this.$store.getters.verifyStatus
        },
        myBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        declined() {
            return this.$store.getters.declined
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return (this.$store.getters.myVerificationBadges || []).some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
    },
    methods: {
        async init() {
            if (!this.verifyStatus) {
                await this.$store.dispatch('loadVerificationBadges')
            }
            await this.$store.dispatch('loadMyVerificationBadges')
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
        },
        async save() {
            try {
                await this.$store.dispatch('loadMyVerificationBadges')
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                console.log(e)
            }
        },
        onClickCertMethod() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://vbproduct.notion.site/ac8a5e85d1e04f0e92a45f879fd289a1`,
            })
        },
        onClickMenu(menu) {
            const verificationBadge = this.$store.getters.verificationBadges.find(
                badge => badge.name === '직장(직업) 인증'
            )
            this.$modal.custom({
                component: 'ModalCertificate',
                options: {
                    verificationBadge,
                    menuId: menu.id,
                },
            })
        },
        back() {
            this.$stackRouter.pop()
        },
        async onClickCertificate() {
            try {
                const result = await verificationBadgeService.postBadge(this.$store.getters.me.id, 'company')
                if (result.msg === 'success') {
                    await this.$modal.basic({
                        body: `고객센터에 문의를 남겼습니다. 고객센터에서 확인 후 연락드리도록 하겠습니다 :)`,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }
            } catch (e) {
                console.log(e)
                this.$toast.error('에러가 발생했습니다. 다시 시도해주세요')
            }
        },
    },

    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.save()
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.bottom-border {
    border-bottom: 1px solid $grey-02;
}
.container {
    color: #111111;

    .wrapper {
        padding: 24px 16px;

        .check-declined {
            @include between;
            padding: 8px;
            background: $bg-danger;
            border-radius: 8px;
            margin-bottom: 24px;

            .left {
                @include center;
            }
        }

        .title {
            @include spoqa-f-bold;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            padding: 30px;
        }
        .menu {
            border: 1px solid $grey-04;
            padding: 25px;
            margin-bottom: 10px;
            border-radius: 8px;
            font-size: 16px;
            color: black;
            @include between;

            .right {
                font-size: 12px;
                @include center;
            }
        }
        .mandate {
            width: calc(100vw - 36px);
            margin: 0 auto;
            border: 1px solid $grey-04;
            border-radius: 8px;
            padding: 18px 12px;
            font-size: 14px;

            .header {
                @include between;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
            }
            .menu {
                .mandate-item {
                    @include between;
                    margin-bottom: 8px;
                    color: black;
                }
            }
            .notice-wrapper {
                color: $grey-08;
                line-height: 20px;
            }
        }

        .help-Certificate {
            display: block;
            color: #7c7873;
            font-size: 14px;
            line-height: 22px;
            text-decoration-line: underline;
            text-align: center;
            margin-top: 30px;
        }
    }
}
</style>
